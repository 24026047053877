<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Prime Survey
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="
                        w-100
                        d-lg-flex
                        align-items-center
                        justify-content-center
                        px-5
                    "
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-50">
            Join Prime Surveys 🚀
          </b-card-title>
          <b-card-text class="mb-3">
            Monetize your app with the number 1 survey platform
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- Name -->
              <b-form-group
                label="Full Name"
                label-for="register-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Full Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="name"
                    name="register-name"
                    :state="
                      errors.length > 0 ? false : null
                    "
                    placeholder="Enter Full Name"
                  />
                  <small class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Company -->
              <b-form-group
                label="Company Name"
                label-for="register-company"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company"
                  vid="company"
                  rules="required"
                >
                  <b-form-input
                    id="register-company"
                    v-model="company"
                    name="register-company"
                    :state="
                      errors.length > 0 ? false : null
                    "
                    placeholder="Your Company"
                  />
                  <small class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Company Email -->
              <b-form-group
                label="Company E-Mail"
                label-for="register-email"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Company E-Mail"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    :state="emailError ? false : null"
                    placeholder="Enter Email"
                    @input="validateEmail"
                  />
                  <small
                    v-if="emailError"
                    class="text-danger"
                  >{{ emailError }}</small>
                  <small
                    v-else-if="errors.length"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="
                      errors.length > 0
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="
                        errors.length > 0 ? false : null
                      "
                      name="register-password"
                      placeholder="Enter Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="
                          togglePasswordVisibility
                        "
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- App/Website URL -->
              <b-form-group
                label="App/Website URL"
                label-for="register-app-url"
              >
                <validation-provider
                  #default="{ errors }"
                  name="App URL"
                  rules="required|url"
                >
                  <b-form-input
                    id="register-app-url"
                    v-model="appWebsiteUrl"
                    :state="errors.length ? false : null"
                    placeholder="Enter URL"
                    @focus="prependHttps"
                    @input="handleTyping"
                    @blur="validateUrl"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <br v-show="errors.length">
                </validation-provider>
                <small class="text-muted">Enter the URL of the app/website you would like to integrate Prime Surveys
                  into</small>
              </b-form-group>

              <hr style="border-top: 1px solid #E5E7EB !important; margin: 2rem 0 2rem;">

              <!-- Survey Monetization Selection -->
              <b-form-group label="Do you work with other survey monetization solutions?">
                <b-form-radio-group
                  v-model="usesSurveyMonetization"
                  class="d-flex"
                >
                  <b-form-radio :value="false">
                    No
                  </b-form-radio>
                  <b-form-radio :value="true">
                    Yes
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <!-- Existing Survey Monetization Solutions (Hidden if "No" is selected) -->
              <b-form-group
                v-if="usesSurveyMonetization"
                label="Which survey monetization solutions do you work with already?"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Survey Monetization Solutions"
                  rules="required"
                >
                  <b-form-textarea
                    id="register-survey-solutions"
                    v-model="existingSurveySolutions"
                    :state="errors.length ? false : null"
                    placeholder="List your survey partners..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Highest Monthly Revenue (Manual Numeric Validation) -->
              <b-form-group
                v-if="usesSurveyMonetization"
                label="What’s the highest monthly revenue you achieved with any of your survey partners in the last 12 months?"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Highest Monthly Revenue"
                  rules="required"
                >
                  <b-form-input
                    id="register-revenue"
                    v-model="highestMonthlyRevenue"
                    type="text"
                    placeholder="Enter in USD"
                    :state="revenueError ? false : null"
                    @input="validateRevenue"
                  />
                  <small
                    v-if="revenueError"
                    class="text-danger"
                  >{{ revenueError }}</small>
                  <small
                    v-else-if="errors.length"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div
                v-if="!usesSurveyMonetization"
                style="margin-bottom: 6rem;"
              />

              <hr style="border-top: 1px solid #E5E7EB; margin: 2rem 0 2rem;">

              <!-- Terms & Conditions -->
              <div class="d-flex align-items-center justify-content-center">
                <b-form-group>
                  <b-form-checkbox v-model="acceptedTerms">
                    <span class="ml-25">I accept the</span>
                    <b-link
                      :to="{ name: 'page-terms-and-conditions' }"
                      target="_blank"
                    >
                      Publisher Terms & Conditions
                    </b-link>
                  </b-form-checkbox>
                </b-form-group>
              </div>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid || !acceptedTerms"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'page-login' }">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
} from 'bootstrap-vue'
import { email, required, url } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      name: '',
      company: '',
      userEmail: '',
      password: '',
      appWebsiteUrl: '',
      usesSurveyMonetization: false,
      existingSurveySolutions: null,
      highestMonthlyRevenue: null,
      acceptedTerms: false,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      url,
      emailError: '', // Custom email validation messages
      revenueError: '', // Custom numeric validation
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    usesSurveyMonetization(newVal) {
      if (!newVal) {
        this.existingSurveySolutions = null
        this.highestMonthlyRevenue = null
      }
    },
  },
  created() {
    if (this.$store.getters['auth/isLoggedIn']) {
      this.$router.push({ paht: '/' })
    }
  },
  methods: {
    validateEmail() {
      const forbiddenDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com']
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const domain = this.userEmail.split('@')[1]

      if (!emailPattern.test(this.userEmail)) {
        this.emailError = 'Please enter a valid email address.'
      } else if (domain && forbiddenDomains.includes(domain.toLowerCase())) {
        this.emailError = 'Please use your company email address.'
      } else {
        this.emailError = ''
      }
    },
    validateRevenue() {
      const revenuePattern = /^[0-9]+(\.[0-9]{1,2})?$/
      if (!this.highestMonthlyRevenue.match(revenuePattern)) {
        this.revenueError = 'Please enter a valid numeric value.'
      } else {
        this.revenueError = ''
      }
    },
    prependHttps() {
      // If empty, immediately set "https://"
      if (!this.appWebsiteUrl) {
        this.appWebsiteUrl = 'https://'
      }
    },
    handleTyping() {
      // Prevent removing "https://" while typing
      if (!this.appWebsiteUrl.startsWith('http://') && !this.appWebsiteUrl.startsWith('https://')) {
        this.appWebsiteUrl = `https://${this.appWebsiteUrl}`
      }
    },
    validateUrl() {
      // Ensure it has a valid prefix after input
      if (this.appWebsiteUrl && !this.appWebsiteUrl.startsWith('http://') && !this.appWebsiteUrl.startsWith('https://')) {
        this.appWebsiteUrl = `https://${this.appWebsiteUrl}`
      }
    },
    async register() {
      const isValid = await this.$refs.registerForm.validate()

      if (isValid) {
        this.showLoading()
        this.$http
          .post('/api/auth/register', {
            email: this.userEmail,
            password: this.password,
            name: this.name,
            company: this.company,
            website_url: this.appWebsiteUrl,
            is_using_other_survey_monetization: this.usesSurveyMonetization,
            other_monetization: this.existingSurveySolutions,
            other_monetization_monthly_revenue: this.highestMonthlyRevenue,
          })
          .then(response => {
            this.hideLoading()

            this.alertSuccess('Registered successfully!')
            const { data } = response
            if (data.result === true) {
              // _this.$store.dispatch("auth/login", data);this.alertSuccess(data.message)
              setTimeout(() => {
                this.$router.push({ path: '/pages/login' })
              }, 1999)
            }
          })
          .catch(error => {
            this.hideLoading()
            this.$refs.registerForm.setErrors(
              error.response.data.errors,
            )
          })
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
