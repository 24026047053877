var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" Prime Survey ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"\n                      w-100\n                      d-lg-flex\n                      align-items-center\n                      justify-content-center\n                      px-5\n                  "},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-50"},[_vm._v(" Join Prime Surveys 🚀 ")]),_c('b-card-text',{staticClass:"mb-3"},[_vm._v(" Monetize your app with the number 1 survey platform ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Full Name","label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"Full Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false : null,"placeholder":"Enter Full Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Company Name","label-for":"register-company"}},[_c('validation-provider',{attrs:{"name":"Company","vid":"company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-company","name":"register-company","state":errors.length > 0 ? false : null,"placeholder":"Your Company"},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Company E-Mail","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Company E-Mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","state":_vm.emailError ? false : null,"placeholder":"Enter Email"},on:{"input":_vm.validateEmail},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),(_vm.emailError)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.emailError))]):(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0
                      ? 'is-invalid'
                      : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"Enter Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"App/Website URL","label-for":"register-app-url"}},[_c('validation-provider',{attrs:{"name":"App URL","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-app-url","state":errors.length ? false : null,"placeholder":"Enter URL"},on:{"focus":_vm.prependHttps,"input":_vm.handleTyping,"blur":_vm.validateUrl},model:{value:(_vm.appWebsiteUrl),callback:function ($$v) {_vm.appWebsiteUrl=$$v},expression:"appWebsiteUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('br',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}]})]}}],null,true)}),_c('small',{staticClass:"text-muted"},[_vm._v("Enter the URL of the app/website you would like to integrate Prime Surveys into")])],1),_c('hr',{staticStyle:{"border-top":"1px solid #E5E7EB !important","margin":"2rem 0 2rem"}}),_c('b-form-group',{attrs:{"label":"Do you work with other survey monetization solutions?"}},[_c('b-form-radio-group',{staticClass:"d-flex",model:{value:(_vm.usesSurveyMonetization),callback:function ($$v) {_vm.usesSurveyMonetization=$$v},expression:"usesSurveyMonetization"}},[_c('b-form-radio',{attrs:{"value":false}},[_vm._v(" No ")]),_c('b-form-radio',{attrs:{"value":true}},[_vm._v(" Yes ")])],1)],1),(_vm.usesSurveyMonetization)?_c('b-form-group',{attrs:{"label":"Which survey monetization solutions do you work with already?"}},[_c('validation-provider',{attrs:{"name":"Survey Monetization Solutions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"register-survey-solutions","state":errors.length ? false : null,"placeholder":"List your survey partners..."},model:{value:(_vm.existingSurveySolutions),callback:function ($$v) {_vm.existingSurveySolutions=$$v},expression:"existingSurveySolutions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.usesSurveyMonetization)?_c('b-form-group',{attrs:{"label":"What’s the highest monthly revenue you achieved with any of your survey partners in the last 12 months?"}},[_c('validation-provider',{attrs:{"name":"Highest Monthly Revenue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-revenue","type":"text","placeholder":"Enter in USD","state":_vm.revenueError ? false : null},on:{"input":_vm.validateRevenue},model:{value:(_vm.highestMonthlyRevenue),callback:function ($$v) {_vm.highestMonthlyRevenue=$$v},expression:"highestMonthlyRevenue"}}),(_vm.revenueError)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.revenueError))]):(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):_vm._e(),(!_vm.usesSurveyMonetization)?_c('div',{staticStyle:{"margin-bottom":"6rem"}}):_vm._e(),_c('hr',{staticStyle:{"border-top":"1px solid #E5E7EB","margin":"2rem 0 2rem"}}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-form-group',[_c('b-form-checkbox',{model:{value:(_vm.acceptedTerms),callback:function ($$v) {_vm.acceptedTerms=$$v},expression:"acceptedTerms"}},[_c('span',{staticClass:"ml-25"},[_vm._v("I accept the")]),_c('b-link',{attrs:{"to":{ name: 'page-terms-and-conditions' },"target":"_blank"}},[_vm._v(" Publisher Terms & Conditions ")])],1)],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || !_vm.acceptedTerms}},[_vm._v(" Sign up ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{ name: 'page-login' }}},[_c('span',[_vm._v(" Sign in instead")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }